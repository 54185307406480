// src/reducer/appReducer.ts
//import { AppState, Action } from "../types";
import { ActionTypes } from "./actionTypes";

export const initialState: AppState = {
  formData: {
    email: "",
    fullName: "",
    firstName: "",
    lastName: "",
    title: "`",
    referralCode: "",
    selectedCountry: "",
    selectedCountryName: "",
    selectedTimezone: "",
    isTermsAccepted: false,
    socialID: null,
    socialType: null,
    phoneCode: "",
    mobileNo: "",
    userType: 4,
    countryID: "",
    userId: "",
    institutionId: "1",
    referenceId: "",
    groupId: "",
    device_type: "",
    device_id: "",
    specialization: "",
  },

  ipCountry: null,
  appLogoUrl: null,
  ipDetails: null,
};

export const appReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case ActionTypes.UPDATE_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    case ActionTypes.SET_COUNTRY:
      return { ...state, ipCountry: action.payload };

    case ActionTypes.SET_APP_LOGO:
      return {
        ...state,
        appLogoUrl: action.payload,
      };

    case ActionTypes.SET_IP_DETAILS:
      return {
        ...state,
        ipDetails: action.payload,
      };

    default:
      return state;
  }
};
