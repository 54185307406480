import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import {
  SendRequestOTPService,
  GetCountryList,
  GetTimezone,
  CheckReferralCode,
  CheckUserEmailIsRegistered,
  RegistrationByOTPService,
  LoginByOTPService,
  OODOCreateUserV2Service,
  VerifyOTP,
  RegisterUserNew,
  CheckUserExists,
  GenerateOTP,
  GetLocationDetails,
} from "../api/_request";
import { Loader2 } from "lucide-react";
import { toast } from "sonner";

import Cookies from "js-cookie";
import EmailInput from "./inputs/emailInput";
import UserNameInput from "./inputs/userNameInput";
import ReferralInput from "./inputs/referralInput";
import CountrySelector from "./selectors/countrySelector";
import TimzoneSelector from "./selectors/timzoneSelector";
import TncAgreeSwitch from "./switch/tncAgreeSwitch";
import OtpVerifierInput from "./inputs/otpVerifierInput";
import { useAppState } from "../context/AppStateContext";
import { ActionTypes } from "../reducer/actionTypes";
import {
  countriesJSON,
  fetchCustomJWT,
  formatTime,
  getDeviceId,
  getDeviceType,
  getSubDomain,
  setCookieOnClientSide,
  validEmailRegex,
} from "../lib/utils";
import Drawer from "./ui/drawer";
import LoginButton from "./buttons/loginButton";
import { useSession, useSignUp, useUser } from "@clerk/clerk-react";

const UserRegisterForm = () => {
  const { state, dispatch } = useAppState();
  const { signUp, setActive } = useSignUp();
  const { session } = useSession();
  //STATES
  const [formData, setFormData] = useState<FormDataInterface>({
    email: "",
    fullName: "",
    firstName: "",
    lastName: "",
    title: "`",
    referralCode: "",
    selectedCountry: "",
    selectedTimezone: "",
    isTermsAccepted: false,
    socialID: null,
    socialType: null,
    mobileNo: "",
    userType: 4,
    countryID: "",
    userId: "",
    institutionId: "1",
    referenceId: "",
    groupId: "",
    device_type: "",
    device_id: "",
  });
  const [step, setStep] = useState<number>(0);
  const [errors, setErrors] = useState<FormErrors>({
    email: "",
    fullName: "",
    referralCode: "",
    otp: "",
  });
  const [countries, setCountries] = useState<any>([]);
  const [timezoneList, setTimezoneList] = useState<any>(null);
  const [disableRegister, setDisableRegister] = useState<boolean>(true);
  const [isOTPServiceCalled, setIsOTPServiceCalled] = useState<boolean>(false);
  const [isRegisterServiceCalled, setIsRegisterServiceCalled] =
    useState<boolean>(false);
  const [counter, setCounter] = useState<number>(30);
  const [otp, setOtp] = useState<string>("");

  const [verifyOTPCounter, setVerifyOTPCounter] = useState<number>(0);
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false);
  const [userCountry, setUserCountry] = useState<string>("");

  const [jwtToken, setJwtToken] = useState<boolean>(false);

  const searchBarParams = new URLSearchParams(window.location.search);
  const redirectURL = searchBarParams.get("redirect");
  const utmSource = searchBarParams.get("utm_source");
  const utmMedium = searchBarParams.get("utm_medium");
  const utmCampaign = searchBarParams.get("utm_campaign");
  const utmID = searchBarParams.get("utm_id");

  ///UseEffect Region Start

  //Single Run useEffect
  useEffect(() => {
    getIPDetails();

    //Get Country list
    getCountryList();
  }, []);

  //Counter for Resend OTP
  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (step === 1) {
      if (counter > 0) {
        timer = setTimeout(() => setCounter(counter - 1), 1000); // Decrease counter every second
      } else if (counter === 0) {
        //setResendAllowed(true); // Enable resend button when countdown reaches 0
      }
    }
    return () => clearTimeout(timer);
  }, [step, counter]);

  //Too many attempts Counter
  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;

    if (verifyOTPCounter > 0) {
      timer = setTimeout(() => setVerifyOTPCounter(verifyOTPCounter - 1), 1000); // Decrease counter every second

      if (step == 0) {
        setErrors((prevData: any) => ({
          ...prevData,
          email: `Too many OTP requests. Please wait ${verifyOTPCounter} seconds before trying again.`,
        }));
      }
      if (step == 1) {
        setErrors((prevData: any) => ({
          ...prevData,
          otp: `Incorrect OTP. Please wait ${formatTime(
            verifyOTPCounter
          )} minutes before attempting again.`,
        }));
      }
    } else if (verifyOTPCounter === 0) {
      //setResendAllowed(true); // Enable resend button when countdown reaches 0
      setErrors((prevData: any) => ({ ...prevData, otp: "" }));
    }

    return () => clearTimeout(timer);
  }, [verifyOTPCounter]);

  // Logic for handling Continue button disabled logic
  useEffect(() => {
    setDisableRegister(!isFormDataValid(formData));

    setFormData((prevData: any) => ({
      ...prevData,
      fullName: `${formData?.firstName} ${formData.lastName}`,
    }));
  }, [formData]);

  // Handle Mobile number and Timezone based on Selected Country
  useEffect(() => {
    if (!formData.selectedCountry) return;

    const countrySettings: any = {
      IN: { mobileNo: "+91 0000000000", timezone: "Asia/Kolkata" },
      CA: { mobileNo: "+1 0000000000", timezone: "America/Toronto" },
    };

    const { mobileNo, timezone } = countrySettings[
      formData.selectedCountry
    ] || {
      mobileNo: "+00 0000000000",
      timezone: null,
    };

    setFormData((prevData) => ({
      ...prevData,
      mobileNo,
      selectedTimezone: timezone,
    }));

    if (formData.selectedCountry) {
      getTimezoneList();
    } else {
      setTimezoneList(null);
    }
  }, [formData.selectedCountry]);

  //Handle Timezone for countries which have only one Timezone
  useEffect(() => {
    if (timezoneList !== null && timezoneList.length == 1) {
      setFormData((prevData) => ({
        ...prevData,
        selectedTimezone: timezoneList[0].name,
      }));
    }
  }, [timezoneList]);

  useEffect(() => {
    if (session && jwtToken) {
      const jwtBody: any = {
        session,
        redirectURL,
        type: "USER",
        regRef: "uwc_user_reg",
        method: "email",
        event: "uwc_register",
        authUserRole: "user",
        provider: formData?.email?.toLocaleLowerCase()?.split("@")[1],
        displayName: formData?.firstName,
      };
      fetchCustomJWT(jwtBody);
    }
  }, [session, jwtToken]);

  //Handle Autosend OTP
  // useEffect(() => {
  //   if (otp.length === 4) {
  //     setDisableRegister(false);
  //     handleRegisterUser();
  //   } else {
  //     setDisableRegister(true);
  //   }
  // }, [otp]);

  ///UseEffects Region End

  ///Functions Region Start

  // Function to validate form data
  const isFormDataValid = (data: any): boolean => {
    return (
      data.email?.trim() !== "" &&
      data.email.toLowerCase().match(validEmailRegex) &&
      data.firstName?.length >= 3 &&
      data.isTermsAccepted &&
      data.selectedCountry !== "" &&
      data.selectedTimezone !== null &&
      data.selectedTimezone !== ""
    );
  };

  const getIPDetails = async () => {
    try {
      const ipDetails = await GetLocationDetails();

      if (ipDetails?.status === 200) {
        const ipTimezone = ipDetails?.data?.data?.timezone;
        const ipCountry = ipDetails?.data?.data?.country;
        const ipRegion = ipDetails?.data?.data?.region;
        const ipCity = ipDetails?.data?.data?.city;
        const ipPostalCode = ipDetails?.data?.data?.postalCode;
        // console.log("ipDetails", ipDetails)

        //Set Phonecode based on selected country
        const formattedData = countriesJSON.filter(
          (item) => item.iso2 === ipCountry
        );
        const formattedCountry = formattedData[0]?.name;
        setUserCountry(ipCountry);
      }
    } catch (error) {}
  };

  const getCountryList = async () => {
    try {
      const res = await GetCountryList();

      //console.log("LIST", res.data.countries);
      setCountries(res.data.countries);
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    }
  };

  const getTimezoneList = async () => {
    const reqBody = {
      code: formData.selectedCountry,
    };
    try {
      const res = await GetTimezone(reqBody);

      //console.log("LIST", res.data.timezones);
      setTimezoneList(res.data.timezones);
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    }
  };

  const handleFormData = (
    field: string,
    value: string | number | null | boolean
  ) => {
    let countryID: any = null;
    let selectedTimezone: any = null;
    if (field === "selectedCountry") {
      countryID = countries.filter((item: any) => item.code == value)[0].id;
      if (+countryID === 104) {
        selectedTimezone = "Asia/Kolkata";
      }
      //console.log("countryID", countryID);
    }

    if (countryID) {
      setFormData((prevData) => ({
        ...prevData,
        selectedTimezone,
        countryID,
        [field]: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };

  const handleSendOTP = async (e?: any) => {
    e.preventDefault();
    setIsOTPServiceCalled(true);
    const isOTPVerified = await GenerateOTP(
      formData?.email?.toLocaleLowerCase()
    );

    if (+isOTPVerified?.data?.code === 200) {
      setCounter(30);
      setErrors((prevData: any) => ({
        ...prevData,
        otp: "",
      }));
      toast.success("OTP sent successfully!");
    } else if (+isOTPVerified?.data?.code === 401) {
      // toast.error(isOTPVerified?.data.message);

      setErrors((prevData: any) => ({
        ...prevData,
        otp: isOTPVerified?.data?.message,
      }));

      if (+isOTPVerified?.data?.data?.retry_after) {
        setVerifyOTPCounter(+isOTPVerified?.data?.data?.retry_after);
        //  setOpenWarningDialog(true);
      }
    } else if (+isOTPVerified?.data?.code === 429) {
      // toast.error(isOTPVerified?.data.message);

      setErrors((prevData: any) => ({
        ...prevData,
        otp: isOTPVerified?.data.message,
      }));

      if (+isOTPVerified?.data?.data?.retry_after) {
        setCounter(+isOTPVerified?.data?.data?.retry_after);
        setVerifyOTPCounter(+isOTPVerified?.data?.data?.retry_after);
        //  setOpenWarningDialog(true);
      }
    } else {
      toast.error("Something went wrong! Please try again.");
      setErrors((prevData: any) => ({
        ...prevData,
        otp: "",
      }));
    }
    setIsOTPServiceCalled(false);
    setOtp("");
  };

  const handleErrors = (field: string, message: string) => {
    setErrors((prevData) => ({
      ...prevData,
      [field]: message,
    }));

    setDisableRegister(false);
  };

  const handleCheckRegister = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    setDisableRegister(true);

    const isEmailAlreadyRegistered = await CheckUserExists(
      formData?.email?.toLocaleLowerCase(),
      "user"
    );

    if (formData.referralCode) {
      const isReferralCodeValid = await checkReferrealCodeResponse();
      if (!isReferralCodeValid) {
        return handleErrors("referralCode", "Referral code is not valid!");
      }
    }

    if (isEmailAlreadyRegistered) {
      setIsOTPServiceCalled(false);
      return handleErrors(
        "email",
        "This email is already registered. Please try logging in!"
      );
    }

    //Generate OTP
    handleSendOTP(e);
    //Go to validate OTP
    setStep(1);
  };

  const checkReferrealCodeResponse = async () => {
    try {
      const res = await CheckReferralCode(formData?.referralCode);
      if (res?.ResponseCode === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      toast("Something went wrong! Please try again.");
    }
  };

  const handleRegisterUser = async (e?: any) => {
    try {
      e?.preventDefault();

      const req = {
        email: formData?.email?.toLocaleLowerCase(),
        //Title: formData?.title,
        name: formData?.fullName,
        phone: formData?.mobileNo,
        referral_code: formData?.referralCode?.toLocaleUpperCase(),
        country: formData?.selectedCountry,
        // UserType: formData?.userType,
        time_zone: formData?.selectedTimezone,
        social_id: formData?.socialID,
        social_type: formData?.socialType,
        device_type: getDeviceType(),
        device_id: getDeviceId(),
      };

      const isRegistrationComplete = await RegisterUserNew(req);
      // console.log("isRegistrationComplete", isRegistrationComplete);
      if (+isRegistrationComplete?.data?.code === 200) {
        const TOKEN = isRegistrationComplete?.data?.data?.token;
        const USERID = isRegistrationComplete?.data?.data?.user_id;
        const USERROLE = isRegistrationComplete?.data?.data?.role;
        const USERJWT = isRegistrationComplete?.data?.data?.jwt;

        //Set Cookie when registration is completed
        const setCookieBody: SetCookieInterface = {
          token: TOKEN,
          userID: USERID,
          userDataJwt: USERJWT,
          utmCampaign,
          utmMedium,
          redirectURL,
          type: "USER",
          regRef: "uwc_user_reg",
          method: "email",
          event: "uwc_register",
          authUserRole: USERROLE,
          provider: formData?.email?.toLocaleLowerCase()?.split("@")[1]
        };
        // toast.success(
        //   "Congratulations! You have successfully registered. Welcome aboard!"
        // );
        setCookieOnClientSide(setCookieBody);
      } else {
        toast.error("Something went wrong! Please try again.");
        setIsRegisterServiceCalled(false);
      }
    } catch (error) {}
  };

  const handleVerifyUser = async (e: any) => {
    e.preventDefault();
    setIsRegisterServiceCalled(true);

    const isOTPVerified = await VerifyOTP(
      formData?.email?.toLocaleLowerCase(),
      +otp,
      state?.ipDetails,
      process.env.REACT_APP_ENV === "production" ? getSubDomain() : "localhost"
    );

    const { code, message, data } = isOTPVerified?.data;

    if (+code === 200) {
      //Register The User
      handleRegisterUser();
    } else if (+code === 401) {
      //toast.error(isOTPVerified?.data.message);

      setErrors((prevData: any) => ({
        ...prevData,
        otp: message,
      }));

      if (+data?.retry_after) {
        setVerifyOTPCounter(+data?.retry_after);
        //setOpenWarningDialog(true);
      }
      setIsRegisterServiceCalled(false);
    } else if (+code === 429) {
      //toast.error(isOTPVerified?.data.message);

      setErrors((prevData: any) => ({
        ...prevData,
        otp: message,
      }));

      if (+data?.retry_after) {
        setCounter(+data?.retry_after);
        setVerifyOTPCounter(+data?.retry_after);
        //setOpenWarningDialog(true);
      }
      setIsRegisterServiceCalled(false);
    } else {
      toast.error(
        "Oops! Something went wrong. Please try requesting the OTP again later."
      );
      setIsRegisterServiceCalled(false);
    }

    setOtp("");
  };

  const handleCloseDialog = () => {
    setOpenWarningDialog(false);
  };

  const getComputedStyleVariable = (variable: string) => {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(variable)
      .trim();
  };

  const primaryColorValue = getComputedStyleVariable("--primary");
  const rgbaColor = primaryColorValue
    ? `rgba(${parseInt(primaryColorValue.slice(1, 3), 16)}, ${parseInt(
        primaryColorValue.slice(3, 5),
        16
      )}, ${parseInt(primaryColorValue.slice(5, 7), 16)}, 0.48)` // Adjust the alpha value as needed
    : "";

  const handleClerkSignUp = async (e: any) => {
    e.preventDefault();
    try {
      // Create the sign-up request

      if (formData.referralCode) {
        const isReferralCodeValid = await checkReferrealCodeResponse();
        if (!isReferralCodeValid) {
          return handleErrors("referralCode", "Referral code is not valid!");
        }
      }

      const signUpResponse = await signUp!.create({
        emailAddress: formData?.email?.toLocaleLowerCase(),
        firstName: formData?.firstName,
        lastName: formData?.lastName,

        unsafeMetadata: {
          login_type: "user",
          referral_code: formData?.referralCode?.toLocaleUpperCase(),
          country: formData?.selectedCountry,
          phone: formData?.mobileNo,
          time_zone: formData?.selectedTimezone,
          social_id: formData?.socialID,
          social_type: formData?.socialType,
          device_type: getDeviceType(),
          device_id: getDeviceId(),
        },
      });

      console.log("signUpResponse", signUpResponse);

      signUp!.prepareEmailAddressVerification();
      setStep(1);
      // if (signUpResponse.status === "complete") {
      //   // Sign-up successful, now create a session
      //   // await setSession(signUpResponse.createdSessionId);
      //   // User is now logged in
      // } else {
      //   // Handle additional steps (e.g., email verification)
      // }
    } catch (error: any) {
      console.error("Error during sign-up:", error?.errors[0]?.code);
      //toast.error(error?.errors[0]?.message);
      if (error?.errors[0]?.code === "form_identifier_exists") {
        setErrors((prevData) => ({
          ...prevData,
          email: "This email is already registered. Please try logging in!",
        }));
      }
    }
  };

  //console.log("user", user);

  const verifyCode = async () => {
    try {
      const verifyResponse = await signUp!.attemptEmailAddressVerification({
        code: otp,
      });

      // console.log("verifyResponse", verifyResponse);
      // await setActive!({ session: signUp?.createdSessionId });
      setJwtToken(true);
    } catch (error: any) {
      console.error("Error during sign-up:", error?.errors[0]?.code);
      toast.error(error?.errors[0]?.message);
    }
  };

  ///Functions Region End

  return (
    <>
      {step === 0 && (
        <div>
          <h6 className="mt-1 text-center text-base">
            Sign up with your email address
          </h6>
          <form id={"user_reg_step1"} className="flex flex-col gap-1 mt-5">
            <EmailInput
              value={formData.email}
              handleFormData={handleFormData}
              error={errors.email}
              setErrors={setErrors}
            />

            <UserNameInput
              firstNameVal={formData.firstName}
              lastNameVal={formData.lastName}
              handleFormData={handleFormData}
              error={errors.fullName}
            />

            <ReferralInput
              referralCode={formData.referralCode}
              error={errors?.referralCode}
              setErrors={setErrors}
              handleFormData={handleFormData}
            />

            <CountrySelector
              handleFormData={handleFormData}
              countries={countries}
            />

            {timezoneList && timezoneList?.length > 1 && (
              <TimzoneSelector
                handleFormData={handleFormData}
                timezoneList={timezoneList}
              />
            )}

            <div className="mt-3">
              <TncAgreeSwitch
                handleFormData={handleFormData}
                isTermsAccepted={formData?.isTermsAccepted}
                userCountry={userCountry}
              />
            </div>

            <button
              style={{
                backgroundColor: disableRegister ? rgbaColor : "var(--primary)",
              }}
              className="sendOTP mt-5"
              disabled={disableRegister}
              onClick={(e) => handleClerkSignUp(e)}
              //onClick={(e) => handleCheckRegister(e)}
            >
              {isOTPServiceCalled && (
                <Loader2 className="animate-spin h-5 w-5 mr-3" />
              )}
              Continue
            </button>
          </form>

          <div className="text-sm text-center text-slate-900 font-semibold mt-6">
            Already have an account? &nbsp;
            <LoginButton redirectURL={redirectURL} type="INLINE" />
          </div>
        </div>
      )}

      {step === 1 && (
        <>
          <h6 className="mt-1  text-center text-base">
            Please enter One Time Password (OTP) sent to your email.
          </h6>
          <div className="flex flex-col gap-2 mt-5">
            <OtpVerifierInput
              otp={otp}
              setOtp={setOtp}
              error={errors.otp}
              setErrors={setErrors}
              counter={verifyOTPCounter}
              id={"user_reg_step2"}
            />

            {verifyOTPCounter === 0 && (
              <div className="mt-5 flex flex-col gap-3">
                {counter > 0 ? (
                  <span className="flex-1 text-sm font-medium text-center text-slate-400 h-9 px-4 py-2">
                    Resend OTP in {counter}s
                  </span>
                ) : (
                  <button className="resendOTP" onClick={handleSendOTP}>
                    Resend OTP
                  </button>
                )}

                <button
                  style={{
                    backgroundColor:
                      otp.length !== 6 || isRegisterServiceCalled
                        ? rgbaColor
                        : "var(--primary)",
                  }}
                  // onClick={handleVerifyUser}

                  onClick={verifyCode}
                  className="sendOTP"
                  disabled={otp.length !== 6 || isRegisterServiceCalled}
                >
                  {isRegisterServiceCalled && (
                    <Loader2 className="animate-spin h-5 w-5 mr-3" />
                  )}
                  Verify OTP
                </button>
              </div>
            )}
          </div>

          {isRegisterServiceCalled ? (
            <div className="fixed w-full h-full top-0 left-0 bg-black/40 backdrop-blur-sm flex items-center justify-center z-20">
              <Loader2 className="animate-spin h-10 w-10 text-white" />
            </div>
          ) : null}
        </>
      )}

      <Drawer
        isOpen={openWarningDialog}
        onClose={handleCloseDialog}
        counter={verifyOTPCounter}
      />
    </>
  );
};

export default UserRegisterForm;
