import React, { useEffect, useState } from "react";
import "./App.css";
import { Toaster } from "sonner";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";

import RegisterRouting from "./components/routing/RegisterRouting";
import TokenLogin from "./components/shared/TokenLogin";
import { GetLocationDetails } from "./api/_request";
import { useAppState } from "./context/AppStateContext";
import { ActionTypes } from "./reducer/actionTypes";
import { Helmet } from "react-helmet";

import useFavicon from "./hooks/useFavicon";

const App: React.FC = () => {
  //Dynamically Change favicon
  const currentHost = window.location.host;
  const faviconUrl = currentHost.includes(".ask-sam.ai")
    ? "/faviconAskSam.ico" // AskSam favicon
    : "/favicon.ico"; // UWC favicon
  useFavicon(faviconUrl);

  const { state, dispatch } = useAppState();

  //const [appLogo, setAppLogo] = useState(process.env.REACT_APP_UWC_LOGO);

  useEffect(() => {
    updateLogo();
    updatePrimaryColor();
    getIPDetails();
  }, []);

  const getIPDetails = async () => {
    try {
      const ipDetails = await GetLocationDetails();
      if (ipDetails?.status === 200) {
        // const ipCountry = ipDetails?.data?.data?.country?.toUpperCase() || "";
        const {
          data: { data },
        } = ipDetails;

        const ipDetailsJSON = {
          city: data?.city,
          country: data?.country,
          latitude: data?.latitude,
          longitude: data?.longitude,
          timezone: data?.timezone,
          region: data?.region,
          regionCode: data?.regionCode,
          postalCode: data?.postalCode,
        };
        //console.log("ipDetails", ipDetailsJSON);

        dispatch({
          type: ActionTypes.SET_IP_DETAILS,
          payload: ipDetailsJSON,
        });
      }
    } catch (error) {
      console.error("Error fetching IP details in getIPDetails function:", error);
    }
  };

  const updateLogo = () => {
    const isAskSam = window.location.host.includes(".ask-sam.ai");

    const logo = isAskSam
      ? process.env.REACT_APP_ASKSAM_LOGO
      : process.env.REACT_APP_UWC_LOGO;

    dispatch({
      type: ActionTypes.SET_APP_LOGO,
      payload: logo,
    });
  };

  const updatePrimaryColor = () => {
    const isAskSam = window.location.host.includes(".ask-sam.ai");
    const isLocal = window.location.host.includes("localhost");

    if (isAskSam) {
      document.documentElement.style.setProperty("--primary", "#0c1c44");
    } else {
      document.documentElement.style.setProperty("--primary", "#73589B");
    }
  };

  return (
    <main>
      <Helmet>
        {window.location.host.includes(".ask-sam.ai") ? (
          <title>Auth AskSam</title>
        ) : (
          <title>Auth UWC</title>
        )}
      </Helmet>
      <Router>
        <div className="flex h-screen w-screen overflow-hidden">
          <TokenLogin />
          <div className="hidden xl:flex xl:flex-col justify-between xl:gap-4 flex-1 bg-[#000601] relative max-w-[800px]">
            <img
              src={state?.appLogoUrl || process.env.REACT_APP_UWC_LOGO}
              alt="logo"
              width={150}
              className="mt-10 object-contain mb-2 ml-8 brightness-[50] z-10 absolute"
            />
            <img
              src={process.env.REACT_APP_LOGIN_IMAGE_URL}
              alt=""
              className="w-full h-screen object-cover"
            />
            {/* <code className="text-white mb-10  text-xs max-w-xl text-center mx-auto">
              Your privacy and security are our top priority. All interactions
              on this platform are protected with advanced encryption and
              managed with strict confidentiality. We implement robust security
              measures to safeguard your personal information
            </code> */}
          </div>
          <div className="flex-1 flex flex-col items-center justify-center px-6 py-14 overflow-hidden h-screen">
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/register/*" element={<RegisterRouting />} />
            </Routes>
          </div>
        </div>
      </Router>
      <Toaster richColors expand={true} />
    </main>
  );
};

export default App;
