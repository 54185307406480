import React from "react";
import { Route, Routes } from "react-router-dom";
import Register from "../../pages/Register";
import RegisterUser from "../../pages/RegisterUser";
import RegisterCopilotExpert from "../../pages/RegisterCopilotExpert";

const RegisterRouting = () => {
  return (
    <Routes>
      <Route path="/" element={<Register />} />
      <Route path="user-register" element={<RegisterUser />} />
      {/* <Route path="user-register/social" element={<RegisterUserSocial />} /> */}
      <Route path="copilot-register" element={<RegisterCopilotExpert />} />
      {/* <Route
        path="copilot-register/social"
        element={<RegisterCopilotSocial />}
      /> */}
    </Routes>
  );
};

export default RegisterRouting;
